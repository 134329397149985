import angular, { IComponentOptions, IController, IOnInit, IOnDestroy } from 'angular';

import { Job } from '../../models/Job';
import { ScheduleService } from '../../services/ScheduleService';
import { ShipInfoService } from '../../services/ShipInfoService';
import { ActivityService, SwitchActivityResponse } from '../../services/ActivityService';

import type { Config } from '../../config';

type ActionCallback = () => (data?: unknown) => void;

class ScheduledJobController implements IController, IOnInit, IOnDestroy {
    static $inject = [
        '$state',
        'scheduleService',
        'shipInfoService',
        'activityService',
        '$mdDialog',
        '$timeout',
        'config',
    ];

    private ribbonIsOpen = false;
    private scheduledJob: Job;
    private cancelAction: ActionCallback;
    private editAction: ActionCallback;
    private shipCurrentTimeZone: string;
    private activityRibbonError: string;
    private activityRibbonDisabled = false;
    private routerTimeout: Promise<any>;

    constructor(
        readonly $state,
        readonly scheduleService: ScheduleService,
        readonly shipInfoService: ShipInfoService,
        readonly activityService: ActivityService,
        readonly mdDialog: any,
        readonly $timeout,
        readonly config: Config) {
    }

    $onInit() {
        this.shipInfoService.getShip().then((ship) => {
            this.shipCurrentTimeZone = ship.administrativeTimezone;
        }).catch((err) => {
            console.log(`Failed to get timezone ${JSON.stringify(err)}`);
            this.shipCurrentTimeZone = 'Etc/UTC';
        });
    }

    $onDestroy() {
        this.$timeout.cancel(this.routerTimeout);
    }

    enableEditVoyage(): boolean {
        return this.config.ENABLE_VOYAGE_UPDATE;
    }

    showMenu(): boolean {
        return this.enableEditVoyage() || !this.scheduledJob.active;
    }

    toggleRibbon = () => {
        this.ribbonIsOpen = !this.ribbonIsOpen;
        this.activityService.setStartingVoyage(this.ribbonIsOpen);
    };

    confirm = () => {
        this.scheduleService.confirm(this.scheduledJob);
    };

    edit = () => {
        this.editAction()(this.scheduledJob);
    };

    onSelectActivityStartJob = (activity) => {
        this.activityService.switchActivity(activity, this.scheduledJob.id)
            .then((response: SwitchActivityResponse) => {
                /*  TODO Remove this and make an explicit check
                    timeout added to give backend more time to be ready and give past activities details
                    since it is async we never know if it is enough but it helps
                    temporary solution..
                 */
                this.routerTimeout = this.$timeout(() => {
                    // On success go to the home activity
                    this.$state.go('home.activity', { startingJobId: this.scheduledJob.id });
                }, 500);
            })
            .catch((error) => {
                if (error.status >= 400) {
                    this.activityRibbonError = 'Unable to start voyage: ' + error.data.message;
                } else {
                    this.activityRibbonError = 'Unable to start voyage. Please try again.';
                }
                this.activityRibbonDisabled = false;
            });
    };

    showJobCancelDialog = (event: Event) => {
        // Appending dialog to document.body to cover sidenav in docs app
        const confirm = this.mdDialog.confirm()
            .title('Do you want to cancel this voyage?')
            .textContent('Voyage: ' + this.scheduledJob.jobName)
            .targetEvent(event)
            .ok('Yes')
            .cancel('No');

        this.mdDialog.show(confirm).then(() => {
            this.cancelAction()(this.scheduledJob);
        }, angular.noop);
    }
}

export class ScheduledJobComponent implements IComponentOptions {
    readonly controller = ScheduledJobController;
    readonly templateUrl = 'scheduled-job/scheduled-job.component.html';
    readonly bindings = {
        scheduledJob: '<',
        cancelAction: '&',
        editAction: '&',
    };
}
