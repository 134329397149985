export class SubscriptionHandlerService<T> {
    private subscribers: any;
    // eslint-disable-next-line
    private firstAdded: Function;
    // eslint-disable-next-line
    private subscriptionAdded: Function;
    // eslint-disable-next-line
    private lastRemoved: Function;

    private cache: T = null;

    constructor() {
        this.subscribers = [];
    }

    /**
     * Adds a subscription.
     * @param subscription The subscription callback method
     * @returns {boolean} True if added, false if already existing.
     */
    // eslint-disable-next-line
    add = (subscription: Function): boolean => {
        let exists;
        if (exists = this.subscribers.indexOf(subscription) === -1) { // eslint-disable-line
            this.subscribers.push(subscription);
        }
        if (this.subscribers.length === 1 && this.firstAdded) {
            this.firstAdded();
        }
        if (this.subscriptionAdded) {
            this.subscriptionAdded();
        }
        if (this.cache) {
            subscription(this.cache);
        }
        return exists;
    };

    /**
     * Removes a subscription
     * @param subscription The subscription callback method
     */
    // eslint-disable-next-line
    remove = (subscription: Function): void => {
        let index;
        if ((index = this.subscribers.indexOf(subscription)) !== -1) {
            this.subscribers.splice(index, 1);
        }
        if (this.subscribers.length === 0 && this.lastRemoved) {
            this.lastRemoved();
        }
    };

    /**
     * Publish content to all subscribers
     * @param payload The content
     */
    publish = (content: T): void => {
        this.cache = content;
        this.subscribers.map((subscription) => {
            subscription(content);
        });
    };

    /**
     * Registers a callback to execute when a subscriber is added
     * @param callback The method to call
     */
    // eslint-disable-next-line
    onSubscription = (callback: Function): void => {
        this.subscriptionAdded = callback;
    };

    /**
     * Registers a callback to execute when the first subscriber is listed
     * @param callback The method to call
     */
    // eslint-disable-next-line
    onFirst = (callback: Function): void => {
        this.firstAdded = callback;
    };

    /**
     * Registers a callback to execute when the last subscriber is removed
     * @param callback The method to call
     */
    // eslint-disable-next-line
    onLast = (callback: Function): void => {
        this.lastRemoved = callback;
    };
}
