export const loginInterceptorProvider = ($httpProvider) => {
    $httpProvider.interceptors.push(['$q', '$injector', ($q, $injector) => {
        return {
            responseError: (response) => {
                const $state = $injector.get('$state');

                if (response.status == 403) {
                    $state.go('login');
                }

                return $q.reject(response);
            },
        };
    }]);
};
