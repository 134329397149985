import { Job } from '../models/Job';
import { SubscriptionHandlerService } from './SubscriptionHandlerService';


export class ScheduleService {
    static $inject = ['$http'];

    private subscriptions: SubscriptionHandlerService<any> = new SubscriptionHandlerService();
    private cycle: number | undefined;

    constructor(private $http) {
        this.subscriptions.onFirst(this.startCycle);
        this.subscriptions.onLast(this.endCycle);
    }

    public subscribe = this.subscriptions.add;
    public publish = this.subscriptions.publish;
    public unsubscribe = this.subscriptions.remove;

    getScheduledJobs = (): angular.IPromise<Job[]> => {
        return this.$http.get('/ship/rest/jobs').then((response) => {
            return response.data;
        });
    };

    private startCycle = () => {
        this.cycle = window.setInterval(this.getUnconfirmedJobs, 5000);
    };

    private endCycle = () => {
        window.clearInterval(this.cycle);
    };

    private getUnconfirmedJobs = (): void => {
        this.$http.get('/ship/rest/jobs/unconfirmed')
            .then((response) => {
                this.subscriptions.publish(response.data);
            });
    };

    getJob = (id: string): angular.IPromise<Job> => {
        return this.$http.get(`/ship/rest/jobs/${id}`).then((response) => {
            return response.data;
        });
    };

    confirm = (job: Job) => {
        return this.$http.put(`/ship/rest/jobs/${job.id}/confirm`).then((response) => {
            if (response.status === 204) {
                job.confirmed = true;
            }
            return response;
        });
    };

    add = (job: Job): angular.IPromise<any> => {
        return this.$http.post('/ship/rest/jobs',job);
    };

    addAndStartAdHocJob = (jobName:string, scheduledTime:Date, activityId: string): angular.IPromise<any> => {
        return this.$http.post('/ship/rest/jobs/start',{
            jobName: jobName,
            scheduledTime: scheduledTime,
            activityId: activityId,
        });
    };

    update = (job: Job): angular.IPromise<any> => {
        return this.$http.put(`/ship/rest/jobs/${job.id}`,job);
    };

    cancel = (job: Job) => {
        return this.$http.put(`/ship/rest/jobs/${job.id}/cancel`);
    };

    finish = (job: Job): angular.IPromise<Job> => {
        return this.$http.put(`/ship/rest/jobs/${job.id}/finish`)
            .then((response) => {
                if (response.status === 204) {
                    return response;
                }
            })
            .catch((response) => {
                console.warn('The ScheduledJob Service could not finish the job.');
                console.warn(response);
            });
    };
}
