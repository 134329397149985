export const routes = ($urlRouterProvider, $stateProvider) => {
    $urlRouterProvider.otherwise(($injector) => {
        $injector.get('$state').go('home.activity');
    });

    $stateProvider
        .state('login', {
            url: '/login',
            template: '<onb-login></onb-login>',
        })

        .state('home', {
            url: '/home',
            template: '<onb-home></onb-home>',
        })

        .state('home.activity', {
            url: '/activity',
            params: {
                startingJobId: null,
            },
            template: '<onb-activity></onb-activity>',
        })

        .state('home.schedule', {
            url: '/schedule',
            template: '<onb-schedule></onb-schedule>',
        })
    ;
};
