declare global {
    interface Window {
        ABOARD_CONFIG: Config;
    }
}

export type Config = {
    env: string
}

const defaults: Config = {
    env: 'local',
};

export const config: Config = Object.assign(defaults, window.ABOARD_CONFIG || {});
