import { IComponentOptions, IController } from 'angular';
import { JobWithActivities } from '../../models/JobWithActivities';

class JobWithActitiviesController implements IController {
    private jobWithActivities: JobWithActivities;
    private remarkIsExpanded = false;
    private refreshJobsAndActivities: () => void;
    private shipCurrentTimeZone: string;

    jobIsPresent = (): boolean => {
        return !!this.jobWithActivities.job;
    };

    toggleRemark = () => {
        if (this.hasRemark()) {
            this.remarkIsExpanded = !this.remarkIsExpanded;
        }
    };

    hasRemark = () => {
        if (this.jobIsPresent()) {
            return !!this.jobWithActivities.job.remark;
        }

        return false;
    };
}

export class JobWithActivitiesComponent implements IComponentOptions{
    readonly controller = JobWithActitiviesController;
    readonly templateUrl = 'job-with-activities/job-with-activities.component.html';
    readonly bindings = {
        jobWithActivities: '<',
        refreshJobsAndActivities: '<',
        shipCurrentTimeZone: '<',
    };
}
