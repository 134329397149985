import moment from 'moment-timezone';

export const formatDateTimeZone = () => {
    return (input, format, timeZone): any => {
        let dateTimeZone = '';

        input = input || '';

        if(timeZone && timeZone !== 'Z') {
            dateTimeZone = moment(input).tz(timeZone).format(format);
        } else {
            dateTimeZone = moment(input).format(format);
        }

        return dateTimeZone;
    };
};
