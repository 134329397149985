import { IComponentOptions, IController } from 'angular';

class AccordionController implements IController {
    private accordionIsOpen = true;
    private allItemsAreSelected: boolean;
    private canBeSelected: boolean;
    private expanded: boolean;
    private group: any;
    private isSelected = false;
    private select: () => void;
    private selectHeader: () => void;

    toggle = () => {
        this.accordionIsOpen = !this.accordionIsOpen;
    };
}

export class AccordionComponent implements IComponentOptions {
    readonly controller = AccordionController;
    readonly templateUrl = 'accordion/accordion.component.html';
    readonly bindings = {
        group: '<',
        selectAllItems: '&',
        expanded: '<',
        canBeSelected: '<',
        allItemsAreSelected: '<',
    };
}
