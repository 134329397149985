import { IComponentOptions, IController, IControllerConstructor, Injectable } from 'angular';

class ToggleCollapseButtonController implements IController {
    expanded: boolean;
}

export class ToggleCollapseButtonComponent implements IComponentOptions {
    readonly bindings: any;
    readonly controller: Injectable<IControllerConstructor>;
    readonly templateUrl: string;

    constructor() {
        this.bindings = {
            expanded: '<',
        };
        this.controller = ToggleCollapseButtonController;
        this.templateUrl = 'toggle-collapse-button/toggle-collapse-button.component.html';
    }
}
