import { Activity } from './Activity';
import { Job } from './Job';

export class JobWithActivities {
    activities: Activity[] = [];
    jobId?: string;
    job?: Job;

    addActivity = (activity: Activity) => {
        this.activities.push(activity);

        if (activity.jobId) {
            this.jobId = activity.jobId;
        }
    };

    setJob = (job?: Job) => {
        if (job) {
            this.job = job;
        }
    };
}
