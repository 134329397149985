import { IComponentOptions, IController, IOnInit, IOnDestroy } from 'angular';
import { ActivityInRibbon } from '../../models/ActivityInRibbon';

class ActivityRibbonCtrl implements IController, IOnInit, IOnDestroy {
    // Each app should register its own ActivityService with the injector
    static $inject = ['activityService', '$timeout'];

    private onSelect: (ActivityInRibbon) => void;
    private errorMessage: string;
    private isDisabled: boolean;
    private disableOnSelect = false;

    private activities: ActivityInRibbon[];
    private selectedActivityId?: string;
    private debounceTimeout: Promise<any>;

    constructor(readonly activityService: any, // ActivityService | ActivityServiceForShore
        readonly $timeout) {
    }

    $onInit() {
        this.activityService.getActivities();
        this.activityService.allowedActivities.subscribe(this.updateActivities);
    }

    $onDestroy() {
        this.$timeout.cancel(this.debounceTimeout);
    }

    updateActivities = (allowedActivities: ActivityInRibbon[]) => {
        this.activities = allowedActivities;
    }

    select = (activity: ActivityInRibbon) => {
        if (this.isDisabled) {
            return;
        }

        if (this.disableOnSelect) {
            this.isDisabled = true;
        }

        this.$timeout.cancel(this.debounceTimeout);

        this.debounceTimeout = this.$timeout(() => {
            this.setSelected(activity.id);
            this.onSelect(activity);
        });
    };

    setSelected = (newSelectedActivityId) => {
        if (newSelectedActivityId) {
            this.selectedActivityId = newSelectedActivityId;
        }
    };
}

export class ActivityRibbonComponent implements IComponentOptions {
    readonly controller = ActivityRibbonCtrl;
    readonly templateUrl = 'activity-ribbon/activity-ribbon.component.html';
    readonly bindings = {
        onSelect: '<',
        selectedActivityId: '=?',
        errorMessage: '=?',
        isDisabled: '=?',
        disableOnSelect: '<?',
    };
}
