import { Injectable, IDirectiveFactory, IDirective } from 'angular';

const factory: IDirectiveFactory = (): IDirective => {
    return {
        link: (scope, element, attrs) => {
            return scope.$watch(attrs.onbHeight, (value: number) => {
                element.attr('height', value);
            });
        },
    };
};

export const heightDirective: Injectable<IDirectiveFactory> = [factory];
