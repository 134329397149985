import { IComponentOptions, IController, IOnInit, IOnDestroy, element } from 'angular';

import { ActivityLogService } from '../../services/ActivityLogService';
import { ScheduleService } from '../../services/ScheduleService';
import { Job } from '../../models/Job';
import { JobWithActivities } from '../../models/JobWithActivities';
import { Activity } from '../../models/Activity';
import { ShipInfoService } from '../../services/ShipInfoService';
import { ActivityService } from '../../services/ActivityService';
import { ActivityInRibbon } from '../../models/ActivityInRibbon';

import type { Config } from '../../config';

const ACTIVITY_RIBBON_OPEN = 'activity-ribbon-open';

class ActivityCtrl implements IController, IOnInit, IOnDestroy {
    static $inject = [
        '$element',
        '$timeout',
        '$stateParams',
        '$filter',
        '$scope',
        'activityLogService',
        'scheduleService',
        'shipInfoService',
        'activityService',
        'config',
    ];

    private pastActivities: Activity[];
    private jobs: Job[] = [];
    private shipInfo: any;
    private activitiesGroupedByJobs: JobWithActivities[] = [];

    private minuteInMs = 6000;
    private durationTillNow = 0;
    private durationTimeout: Promise<any>;
    private activeJob: Job | undefined;
    private activeJobId: string | null;
    private refreshingActivities = false;

    private animationContainer;
    private shipCurrentTimeZone: string;
    private isStartingVoyage = false;
    private isStoppingVoyage = false;

    private voyageProcessingAlert = false;
    private voyageAlertTimeout: Promise<any>;

    private showActivitiesRibbon = false;
    private onSelectActivityAction: string;
    private activityRibbonDisabled = false;

    private currentActivityLoaded = false;
    private currentActivity: Activity = null;

    private jobStartedFromSchedulePage: string = null;
    private refreshTimeout: Promise<any>;

    constructor(
        readonly $element,
        readonly $timeout,
        readonly $stateParams,
        readonly $filter,
        readonly $scope,
        readonly activityLogService: ActivityLogService,
        readonly scheduleService: ScheduleService,
        readonly shipInfoService: ShipInfoService,
        readonly activityService: ActivityService,
        readonly config: Config,
    ) {
        this.activityLogService.subscribe(this.onActivityUpdate);
    }

    $onInit() {
        this.jobStartedFromSchedulePage = this.$stateParams.startingJobId;
        this.activityLogService.startOrContinueShift()
            .catch((err) => {
                console.log(`Failed to start or continue shift ${JSON.stringify(err)}`);
            });
        this.refreshJobsAndActivities();
        this.scheduleDurationUpdate();
        this.shipInfoService.subscribe(this.setShipInfo);

        this.shipInfoService.getShip().then((ship) => {
            this.shipCurrentTimeZone = ship.administrativeTimezone;
        }).catch((err) => {
            console.log(`Failed to get current time zone ${JSON.stringify(err)}`);
        });

        // Check if we started the job from the schedule page
        if (this.jobStartedFromSchedulePage) {
            // Current activity not yet available. Wait for it
            if (!this.currentActivity) {
                this.setIsStartingVoyage(true);
                // Current activity available and job seem to be started
            } else if (this.jobStartedFromSchedulePage == this.currentActivity.jobId) {
                this.setIsStartingVoyage(false);
                this.jobStartedFromSchedulePage = null;
            }
        }
        //this.scheduleActivityRefresh();
    }

    $onDestroy = () => {
        this.shipInfoService.unsubscribe(this.setShipInfo);
        this.activityLogService.unsubscribe(this.onActivityUpdate);

        this.$timeout.cancel(this.durationTimeout);
        this.$timeout.cancel(this.voyageAlertTimeout);
        this.$timeout.cancel(this.refreshTimeout);

        this.currentActivityLoaded = false;
        this.animationContainer = null;
    };

    $postLink() {
        this.animationContainer = element(document.querySelector('.activity__container'));
    }

    onActivityUpdate = (activity: Activity) => {
        // A flag to check if the current activity has been loaded initially
        if (!this.currentActivityLoaded) {
            this.currentActivityLoaded = true;
        }

        // If no updated has been received do nothing.
        if (!activity || (this.currentActivity && this.currentActivity.id === activity.id)) {
            return;
        }

        this.currentActivity = activity;
        this.activeJobId = activity.jobId;
        this.setIsStoppingVoyage(false);

        // Check if the start job has been processed
        if (this.jobStartedFromSchedulePage) {
            if (this.jobStartedFromSchedulePage === this.activeJobId) {
                this.setIsStartingVoyage(false);
                this.jobStartedFromSchedulePage = null;
            } else {
                this.setIsStartingVoyage(true);
            }
        } else {
            this.setIsStartingVoyage(false);
        }

        // When there isn't an active job ID,
        // reset the activeJob and the current `shift` will be shown.
        // No more checks are needed.
        if (!this.activeJobId) {
            this.activeJob = null;
            return;
        }

        // And refresh when the job IDs do not match
        if (this.activeJobId && !this.activeJob || (this.activeJobId !== this.activeJob.id)) {
            this.refreshJobsAndActivities();
        }
    };

    findJob = (id: string): Job | undefined => {
        return this.jobs.find((job) => job.id === id);
    };

    refreshJobsAndActivities = () => {
        this.activityLogService.jobsForCurrentShift().then((jobs) => {
            this.jobs = jobs;
            this.activeJob = jobs.find((job) => {
                return job.active;
            });
            this.updateDuration();
            this.getPastActivities();
        })
        .catch((err) => {
            console.log(`Failed to get jobsForCurrentShift ${JSON.stringify(err)}`);
        });
    };

    scheduleDurationUpdate = () => {
        this.durationTimeout = this.$timeout(() => {
            this.updateDuration();
            this.scheduleDurationUpdate();
        }, this.minuteInMs);
    };

    updateDuration = () => {
        if (this.activeJob) {
            const nowInMs = new Date().getTime();
            const startDateInMs = new Date(this.activeJob.startTime).getTime();

            this.durationTillNow = nowInMs - startDateInMs;
        }
    };

    // Close ribbon when details or current activity are also closed
    onCurrenActivityToggleDetails = (open: boolean) => {
        if (open === false) {
            this.closeRibbon();
        }
    };

    closeRibbon = () => {
        this.onSelectActivityAction = null;
        this.activityRibbonDisabled = false;
        this.showActivitiesRibbon = false;
        this.animationContainer.removeClass(ACTIVITY_RIBBON_OPEN);
    };

    openRibbon = (action: string) => {
        if (this.showActivitiesRibbon) {
            return;
        }

        this.animationContainer.addClass(ACTIVITY_RIBBON_OPEN);
        this.showActivitiesRibbon = true;
        this.onSelectActivityAction = action;
        this.activityService.setStartingVoyage(this.onSelectActivityAction === 'START_VOYAGE');
    };

    onSelectActivity = (activity: ActivityInRibbon) => {
        switch (this.onSelectActivityAction) {
            case 'START_ACTIVITY': return this.startActivity(activity);
            case 'START_VOYAGE': return this.startVoyage(activity);
            case 'STOP_VOYAGE': return this.stopVoyage(activity);
        }
    };

    startActivity = (activity: ActivityInRibbon) => {
        this.switchActivity(activity, this.activeJobId);
    };

    startVoyage = (activity: ActivityInRibbon) => {
        const now: Date = new Date();
        this.setIsStartingVoyage(true);
        this.scheduleService.addAndStartAdHocJob(this.createJobName(now), now, activity.id).then((response) => {
            this.closeRibbon();
            this.refreshJobsAndActivities();
        }).catch(($error) => {
            this.closeRibbon();
        });
    };

    stopVoyage = (activity: ActivityInRibbon) => {
        this.activeJobId = null;
        this.setIsStoppingVoyage(true);
        this.switchActivity(activity, null);
    };

    enableStartActivity = (): boolean => {
        return this.currentActivityLoaded
            && this.showActivitiesRibbon !== true
            && !this.isChangingVoyageStateOnBackend();
    };

    enableStartVoyage = (): boolean => {
        return this.config.ENABLE_VOYAGE_CREATE === true
            && this.currentActivityLoaded
            && this.showActivitiesRibbon !== true
            && !this.isChangingVoyageStateOnBackend();

    };

    enableStopVoyage = (): boolean => {
        return this.currentActivityLoaded
            && this.showActivitiesRibbon !== true
            && typeof this.activeJobId === 'string'
            && !this.isChangingVoyageStateOnBackend();
    };

    showHintStartActivity = (): boolean => {
        return this.currentActivityLoaded &&
            (this.currentActivity === undefined || this.currentActivity === null)
            && !this.isChangingVoyageStateOnBackend();
    };

    private setIsStartingVoyage(flag: boolean) {
        this.isStartingVoyage = flag;
        this.scheduleVoyageProcessingAlert(flag);
    }

    private setIsStoppingVoyage(flag: boolean) {
        this.isStoppingVoyage = flag;
        this.scheduleVoyageProcessingAlert(flag);
    }

    private scheduleVoyageProcessingAlert = (flag: boolean) => {
        // Reset the previous state
        this.voyageProcessingAlert = false;

        // Set the timer to show the message after 3 sec
        if (flag === true) {
            this.voyageAlertTimeout = this.$timeout(() => {
                this.voyageProcessingAlert = true;
            }, 3000);
        }
    };

    private isChangingVoyageStateOnBackend() {
        return this.isStartingVoyage || this.isStoppingVoyage;
    }

    private switchActivity = (activity: ActivityInRibbon, jobId: string) => {
        this.activityService.switchActivity(activity, jobId)
            .then((response: any) => {
                if (!jobId) {
                    this.activeJobId = null;
                    this.activeJob = null;
                }
                this.closeRibbon();
                this.refreshJobsAndActivities();
            })
            .catch((error) => {
                if (error.status >= 400) {
                    //this.serverErrorMessage = error.data.message;
                }
                this.closeRibbon();
            });
    };

    private setShipInfo = (shipInfo) => {
        return this.shipInfo = shipInfo;
    };

    private scheduleActivityRefresh = () => {
        // Schedule a refresh of the jobs and activities every 30 sec
        if (!this.refreshingActivities) {
            this.refreshingActivities = true;

            const refreshAndReset = () => {
                this.refreshJobsAndActivities();
                this.refreshingActivities = false;
                this.scheduleActivityRefresh();
            };

            this.refreshTimeout = this.$timeout(refreshAndReset, 30000);
        }
    };

    private getPastActivities = () => {
        this.activityLogService.getActivityHistory().then((result: Activity[]) => {
            this.pastActivities = result;
            this.groupActivitiesUnderJobs();
        });
    };

    /**
     * Some activities are nested under a job. Others are started by the captain when he didn't
     * start a job first, for example, when he transited to a berth to rest.
     */
    private groupActivitiesUnderJobs = () => {
        let currentJobWithActivities: JobWithActivities;
        const groupedActivities = [];

        const activityIsNotNested = (activity) => {
            return !activity.jobId;
        };

        const activityHasADifferentJob = (activity) => {
            return activity.jobId !== currentJobWithActivities.jobId;
        };

        this.pastActivities.forEach((activity) => {
            if (!currentJobWithActivities) {
                currentJobWithActivities = new JobWithActivities();
            } else if (activityIsNotNested(activity) || activityHasADifferentJob(activity)) {
                groupedActivities.push(currentJobWithActivities);
                currentJobWithActivities = new JobWithActivities();
            }
            currentJobWithActivities.addActivity(activity);

            if (activity.jobId) {
                currentJobWithActivities.setJob(this.findJob(activity.jobId));
            }

            if (activity.jobId && !currentJobWithActivities.job) {
                this.scheduleService.getJob(activity.jobId).then((response) => {
                    currentJobWithActivities.job = response;
                });
            }
        });

        if (currentJobWithActivities) {
            groupedActivities.push(currentJobWithActivities);
        }

        this.activitiesGroupedByJobs = groupedActivities;
    };

    private createJobName(date: Date) {
        return `Voyage ${this.$filter('formatDateTimeZone')(date, 'DD-MM-YYYY hh:mm:ss A')}`;
    }
}

export class ActivityComponent implements IComponentOptions {
    readonly controller = ActivityCtrl;
    readonly templateUrl = 'activity/activity.component.html';
}
