import { IComponentOptions, IController } from 'angular';
import { GraphQLClient } from '../../graphql/GraphQLClient';
import { ActivityLogService } from '../../services/ActivityLogService';

class LoginController implements IController {
    static $inject = ['$http', '$httpParamSerializer', '$state', ActivityLogService.IID, GraphQLClient.IID];
    private userName: string;
    private password: string;
    private rememberMe: boolean;
    private loginInProgress;
    private errorMsg: string;

    constructor(readonly $http,
                readonly $httpParamSerializer,
                readonly $state,
                readonly activityLogService: ActivityLogService,
                readonly graphQLClient: GraphQLClient) {
    }

    toLowerCase = () => {
      this.userName = this.userName?.toLowerCase();
    };

    login = () => {
        if(!this.password && !this.userName) {
            this.errorMsg = 'PLEASE FILL IN THE FORM';
            this.loginInProgress = false;
        }

        this.loginInProgress = true;
        if (this.userName != window.localStorage.userName) {
            window.localStorage.clear();
        }

        let privateDevice;
        if (this.rememberMe) {
            window.localStorage.userName = this.userName;
            privateDevice = true;
        } else {
            window.localStorage.removeItem('userName');
            privateDevice = false;
        }

        this.graphQLClient.login(this.userName, this.password)
            .catch((err) => {
                console.error(`Login failed; ${err}`);
                this.errorMsg = 'LOGIN_FAILED';
                this.loginInProgress = false;
            });

        const loginData = {
            providerType: 'local',
            accountId: this.userName,
            password: this.password,
            privateDevice: privateDevice,
            userAgent: navigator.userAgent,
            relogin: false,
        };

        this.$http({
            method: 'POST',
            url: '/auth/rest/login',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                'X-Requested-With': 'XMLHttpRequest',
            },
            transformRequest: this.$httpParamSerializer,
            /*
             * When authentication fails, Amdatu Security does response with a content-type "application/json" but without a valid JSON message body.
             * The HttpProvider then tries to parse the response using a JSON parsers and fails. To overcome this problem we do it ourselves and return
             * a valid JSON body when authentication failed.
             */
            transformResponse: (value) => {
                try {
                    return JSON.parse(value);
                } catch (e) {
                    return {message: value};
                }
            },
            data: loginData,
        }).then((response) => {
                this.$state.go('home.activity');
                this.loginInProgress = false;
                this.errorMsg = null;
        }).catch(() => {
            this.errorMsg = 'LOGIN_FAILED';
            this.loginInProgress = false;
        });
    };
}

export class LoginComponent implements IComponentOptions {
    readonly controller = LoginController;
    readonly templateUrl = 'login/login.component.html'
}
