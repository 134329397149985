import { IComponentOptions, IController, IOnInit, IOnDestroy } from 'angular';

import { LayoutService } from '../../services/LayoutService';
import { ScheduleService } from '../../services/ScheduleService';

import type { Config } from '../../config';

class NavigationController implements IController, IOnInit, IOnDestroy {
    static $inject = [
        'layoutService',
        'scheduleService',
        'config',
    ];

    private nightModeOn = false;
    private shoreConnectionAvailable: boolean;
    private amountOfUnconfirmedJobs: any;

    constructor(
        readonly layoutService: LayoutService,
        readonly scheduleService: ScheduleService,
        readonly config: Config) {
    }

    $onInit() {
        this.scheduleService.subscribe(this.setUnconfirmedJobs);
    }

    $onDestroy() {
        this.scheduleService.unsubscribe(this.setUnconfirmedJobs);
    }

    private setUnconfirmedJobs = (amount: number) => {
        this.amountOfUnconfirmedJobs = amount;
    };

    toggleNightmode() {
        this.nightModeOn = this.layoutService.toggleNightmode();
    }
}

export class NavigationComponent implements IComponentOptions {
    readonly controller = NavigationController;
    readonly templateUrl = 'navigation/navigation.component.html';
    readonly bindings = {
        shoreConnectionAvailable: '<',
    };
}
