import { gql } from 'graphql-tag';

export const ACTIVITY_NUMBERS = gql`
  query ActivityNumbers($between: TimeFilter!, $targetLiterPerHour: Float, $targetLiterPerNauticalMile: Float) {
    measurements(between: $between) {
      duration {
        duration
        durationSeconds
      }
      speedOverGround {
        nauticalMile
      }
      speedThroughWater {
        nauticalMile
      }
      fuelEconomy {
        perHour {
          liter
          avgLiterPerHour
          efficiency(targetLiterPerHour: $targetLiterPerHour)
        }
        overGround {
          literPerNauticalMile
          efficiency(targetLiterPerNauticalMile: $targetLiterPerNauticalMile)
        }
        throughWater {
          literPerNauticalMile
          efficiency(targetLiterPerNauticalMile: $targetLiterPerNauticalMile)
        }
      }
    }
  }
`;
