import { Injectable, IDirectiveFactory, IDirective } from 'angular';

const factory: IDirectiveFactory = (): IDirective => {
    return {
        link: (scope, element, attrs) => {
            return scope.$watch(attrs.onbY, (value: number) => {
                element[0].tagName === 'circle' ? element.attr('cy', value) : element.attr('y', value);
            });
        },
    };
};

export const yDirective: Injectable<IDirectiveFactory> = [factory];
