import moment from 'moment-timezone';

import { IComponentOptions, IController, IOnInit, IOnDestroy } from 'angular';
import { Activity } from '../../models/Activity';
import { ActivityService } from '../../services/ActivityService';
import { ActivityLogService, EditActivityData } from '../../services/ActivityLogService';
import { ShipInfoService } from '../../services/ShipInfoService';

import type { Config } from '../../config';

/*
* Add or subtract to the timstamp the following: the difference between the UTC offsets of the
* fromZone and toZone timezones.
*
* This is rather dangerous and a pretty bad idea to do in the front end, as the 'same local time'
* is ambiguous due to daylight saving time transitions. However, the date picker always uses
* uses the operating system's timezone to convert the local time to the UTC zone. However, we want
* to interpret the given, local time in another timezone.
*
* This returns an timestamp value in milliseconds from the start of the Unix epoch.
*/
const getTimeWithSameLocaltime = (timestampInMs: number, fromZone: string, toZone: string): number => {
    // create moment date in fromtimezone
    const inputDate = moment.unix(timestampInMs / 1000).tz(fromZone);

    // parse the local time (without UTC offset) as toZone local time, creating a
    // different time.
    return moment.tz(inputDate, toZone).valueOf();
};

class PastActivityController implements IController, IOnInit, IOnDestroy {
    static $inject = [
        '$timeout',
        'activityLogService',
        'activityService',
        'shipInfoService',
        'config',
    ];

    private refreshJobsAndActivities: () => void;
    private pastActivity: Activity;
    private isNestedUnderJob: boolean;
    private showDetails = false;
    private editMode = false;

    private equipment: string[] = [];
    private equipmentChanged = false;
    private equipmentUpdated = false;
    private remarkChanged = false;
    private remarkUpdated = false;
    private isEditEquipmentEnabled = true;

    // the time we use in the datepicker, which is a *different* time because datepicker
    // only works in the browser timezone (this is an ugly hack)
    private datepickerStartDisplayTime: Date;
    private datepickerFinishDisplayTime: Date;
    private editActivityPayload: EditActivityData;
    private activityDetailsChanged = false;
    private isDisabled = true;
    private serverErrorMessage: string;
    private selectedActivityId: string;
    private hasEquipment = false;
    private originalIcon: string;
    private originalActivityName: string;
    private shipCurrentTimeZone: string;
    private refreshTimeout: Promise<any>;

    constructor(
        readonly $timeout,
        readonly activityLogService: ActivityLogService,
        readonly activityService: ActivityService,
        readonly shipInfoService: ShipInfoService,
        readonly config: Config) {
    }

    $onInit() {
        this.editActivityPayload = {
            activityId: this.pastActivity.activityId,
            started: null,
            finished: null,
        };

        this.originalActivityName = this.pastActivity.activityName;
        this.originalIcon = this.pastActivity.icon;

        this.getEquipmentWithValue();
    }

    $onDestroy() {
        this.$timeout.cancel(this.refreshTimeout);
    }

    private async getEquipment(): Promise<string[]> {
        const { equipment = [] } = await this.shipInfoService.getShip().catch((err) => {
            console.log(`Failed to get equipment ${JSON.stringify(err)}`);
            return {equipment: []};
        });

        return equipment;
    }

    isFinished = (): boolean => {
        return !!this.pastActivity.finished;
    };

    jobDuration = (): number => {
        if (this.pastActivity && this.pastActivity.fuelEfficiency) {
            return this.pastActivity.fuelEfficiency.durationSeconds;
        }

        return 0;
    };

    async toggleDetails() {
        // Get equipment details before expanding the item
        if (this.isEditEquipmentEnabled && !this.showDetails) {
            this.equipment = await this.getEquipment();
        }

        this.showDetails = !this.showDetails;
    }

    enableEditActivity(): boolean {
        return this.config.ENABLE_ACTIVITY_UPDATE && !this.editMode;
    }

    startEdit = () => {
        this.editMode = true;
        this.selectedActivityId = this.pastActivity.activityId;

        const browserZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

        // Change the time when editing a ship in another timezone
        this.datepickerStartDisplayTime = new Date(getTimeWithSameLocaltime(this.pastActivity.started, this.shipCurrentTimeZone, browserZone));
        this.datepickerFinishDisplayTime = new Date(getTimeWithSameLocaltime(this.pastActivity.finished, this.shipCurrentTimeZone, browserZone));

        this.datepickerStartDisplayTime.setMilliseconds(0);
        this.datepickerFinishDisplayTime.setMilliseconds(0);
    };

    cancelEdit = () => {
        this.editMode = false;
        this.serverErrorMessage = null;
        this.isDisabled = true;

        this.pastActivity.activityName = this.originalActivityName;
        this.pastActivity.icon = this.originalIcon;

        this.datepickerStartDisplayTime = null;
        this.datepickerFinishDisplayTime = null;
    };

    timeIsChanged = (original: number, edited: number): boolean => {
        return original !== edited;
    };

    activityTitleIsChanged = (): boolean => {
        return this.editActivityPayload.activityId !== this.pastActivity.activityId;
    };

    onSelectActivity = (selected: any) => {
        this.isDisabled = false;
        this.editActivityPayload.activityId = selected.id;
        this.pastActivity.activityName = selected.name;
        this.pastActivity.icon = selected.icon;
    };

    setRemarkChanged = () => {
        this.remarkChanged = true;
    };

    setEquipmentChanged = () => {
        this.equipmentChanged = true;
    };

    saveActivity = () => {
        if (!this.datepickerStartDisplayTime || !this.datepickerFinishDisplayTime) {
            this.serverErrorMessage = 'Start or end date may not be empty.';
            return;
        }

        // convert trickily set datepicker-time-in-browser-timezone back to actual ship timezone, preserving the
        // local time representation (and thus not the actual millis ts instant).
        const browserZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
        let newStartDateTime = getTimeWithSameLocaltime(this.datepickerStartDisplayTime.getTime(), browserZone, this.shipCurrentTimeZone);
        let newFinishDateTime = getTimeWithSameLocaltime(this.datepickerFinishDisplayTime.getTime(), browserZone, this.shipCurrentTimeZone);

        // Set the original milliseconds back into the date objects since the input datepicker lost the milliseconds
        newStartDateTime += (this.pastActivity.started % 1000);
        newFinishDateTime += (this.pastActivity.finished % 1000);

        const startTimeIsChanged = this.timeIsChanged(this.pastActivity.started, newStartDateTime);
        const finishTimeIsChanged = this.timeIsChanged(this.pastActivity.finished, newFinishDateTime);

        if (startTimeIsChanged || finishTimeIsChanged || this.activityTitleIsChanged()) {
            this.editActivityPayload.started = newStartDateTime;
            this.editActivityPayload.finished = newFinishDateTime;

            this.activityLogService.editActivity(this.pastActivity.id, this.editActivityPayload)
                .then((response) => {
                    if (response.status === 204) {
                        this.activityDetailsChanged = true;
                        this.refreshTimeout = this.$timeout(() => {
                            this.refreshJobsAndActivities();
                        }, 3000);
                    } else if (response.status > 399) {
                        if (response.data && response.data.message) {
                            this.serverErrorMessage = response.data.message;
                        } else {
                            this.serverErrorMessage = 'The activity couldn\'t be changed.';
                        }
                    }
                });
        } else {
            this.serverErrorMessage = 'Nothing has been changed.';
        }
    };

    getEquipmentWithValue = () => {
        if (this.pastActivity.equipment) {
            Object.keys(this.pastActivity.equipment).forEach((key) => {
                if (this.pastActivity.equipment[key] === true) {
                    this.hasEquipment = true;
                }
            });
        }
    };

    async updateEquipment() {
        const { id, equipment } = this.pastActivity;

        try {
            await this.activityService.updateEquipment(id, equipment);

            this.equipmentChanged = false;
            this.equipmentUpdated = true;
        } catch (err) {
            console.error('In updateEquipment(), something went wrong.', err);
        }
    }

    async updateRemark() {
        const { id, remark } = this.pastActivity;

        try {
            await this.activityService.updateRemark(id, remark);

            this.remarkChanged = false;
            this.remarkUpdated = true;
        } catch (err) {
            console.error('In updateRemark(), something went wrong.', err);
        }
    }
}

export class PastActivityComponent implements IComponentOptions {
    readonly controller = PastActivityController;
    readonly templateUrl = 'past-activity/past-activity.component.html';
    readonly bindings = {
        pastActivity: '<',
        isNestedUnderJob: '<',
        refreshJobsAndActivities: '<',
        shipCurrentTimeZone: '<',
    };
}
