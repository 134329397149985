import clone from 'lodash/clone';
import isEqualWith from 'lodash/isEqualWith';
import isEqual from 'lodash/isEqual';
import orderBy from 'lodash/orderBy';

import { IComponentOptions, IController, IOnInit, IOnDestroy } from 'angular';
import { ScheduleService } from '../../services/ScheduleService';
import { Job } from '../../models/Job';
import { JobNew } from '../../models/JobNew';

import type { Config } from '../../config';

class ScheduleController implements IController, IOnInit, IOnDestroy {
    static $inject = [
        '$timeout',
        'scheduleService',
        'config',
    ];

    private timeoutTask: Promise<any>;
    scheduledJobs: Array<Job>;

    job: Job | null = null;
    isEdit = false;

    constructor(readonly $timeout,
                readonly scheduleService: ScheduleService,
                readonly config: Config) {
    }

    $onInit() {
      this.getJobs();
      this.scheduleGetJobs();
    }

    $onDestroy() {
      this.$timeout.cancel(this.timeoutTask);
    }

    enableAddVoyage = (): boolean => {
        return this.job === null && this.config.ENABLE_VOYAGE_CREATE === true;
    };

    scheduleGetJobs = () => {
        this.timeoutTask = this.$timeout(() => {
            if(!this.isEdit){
                // To avoid synchronization issues, pause updating the jobs when we are editing one
                this.getJobs();
            }
            this.scheduleGetJobs();
        }, 1000);
    };

    unconfirmedCount = () => {
        return this.scheduledJobs ? this.scheduledJobs.filter((scheduledJob) => {
            return !scheduledJob.confirmed;
        }).length : 0;
    };

    createNewJob = () => {
        this.isEdit = false;
        this.job = new JobNew();
    };

    editJob = (job: Job) => {
        this.isEdit = true;
        this.job = clone(job);
        this.job.scheduledTime = new Date(this.job.scheduledTime.toString());
    };

    cancelEditJob(){
        this.job = null;
    }

    addNewJob(){
        if(this.job == null){
            return;
        }
        this.scheduleService.add(this.job).then(() => {
            this.job = null;
            this.getJobs();
        });
    }

    updateExistingJob(){
        if(this.job == null){
            return;
        }
        this.scheduleService.update(this.job).then(() => {
            this.job = null;
            this.getJobs();
        });
    }

    cancelJob = (job: Job) => {
        this.scheduleService.cancel(job).then(() => {
            this.getJobs();
        });
    };

    isSelected = (job: Job): boolean => {
        // Compare by $$hashKey due to when editing and properties change we still want to know if the item is selected
        return job && this.job && job['$$hashKey'] && job['$$hashKey'] === this.job['$$hashKey'];
    };

    private isJobEqual = (job1: Job, job2: Job): boolean => {
        return isEqualWith(job1,job2,(leftVal: any,rightVal: any,key: string): boolean => {
            // Job from REST service has a serialized scheduledTime date as String
            // Using a customizer to compared this value to check if objects are equals by values
            if(key === 'scheduledTime'){
                return isEqual(new Date(leftVal),new Date(rightVal));
            }
            return;
        });
    };

    private getJobs = () => {
        this.scheduleService.getScheduledJobs().then((jobs) => {
            // For proper deep comparison we need to compare the sorted arrays
            const sortedJobs = orderBy(jobs, ['active', 'scheduledTime'], ['desc', 'asc']);
            if (this.isJobsChanged(this.scheduledJobs, sortedJobs)) {
                this.scheduledJobs = sortedJobs;
            }
        })
        .catch((err) => {
            console.log(`Failed to get scheduled jobs ${JSON.stringify(err)}`);
        });
    };

    private isJobsChanged = (existing: Array<Job>, newCollection: Array<Job>) => {
        if( (!existing && newCollection)
            || (existing && existing.length !== newCollection.length)){
            return true;
        }
        // Deep comparison using json due to angular $$hashkey
        return this.toJSONStringWithoutHashkey(existing) !== this.toJSONStringWithoutHashkey(newCollection);
    };

    private toJSONStringWithoutHashkey = (object: any) : string =>{
        return JSON.stringify(object, ( key, value ) => {
            if( key === '$$hashKey' ) {
                return undefined;
            }
            return value;
        });
    }
}

export class ScheduleComponent implements IComponentOptions {
    readonly controller = ScheduleController;
    readonly templateUrl = 'schedule/schedule.component.html';
}
