export class ToastService {
    static $inject = ['$mdToast'];

    private notification;

    constructor(private $mdToast) { }

    notify(message: string) {
        if (this.notification) {
            this.$mdToast.updateTextContent(message);

            return;
        }

        const config = this.$mdToast
            .simple()
            .textContent(message)
            .position('top right')
            .hideDelay(5000);

        this.notification = this.$mdToast
            .show(config)
            .then(() => {
                this.notification = false;
            });
    }

    showUpdate(message: string) {
        const config = this.$mdToast
            .simple()
            .textContent(message)
            .action('Update')
            .actionKey('r')
            .highlightAction(true)
            .position('bottom right')
            .hideDelay(0);

        this.$mdToast
            .show(config)
            .then(function (result: string) {
                if (result === 'ok') {
                    window.location.reload();
                }
            });
    }
}
