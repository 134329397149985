import { IComponentOptions, IController, IControllerConstructor, Injectable } from 'angular';

class CircleCtrl implements IController {
    static $inject = ['$element', '$filter'];

    private badge = false;
    private part = 0;
    private parts: Array<number>;
    private size = 0;
    private total: Array<number>;
    private icon: string;
    private unit: string;
    private filter: any;
    private units: Array<string>;
    private defaultUnit: string;

    private center = 1;
    private radius = 1;
    private stroke = 2; // also used in circle css
    private carouselIndex = 0;

    constructor(
        readonly $element,
        readonly $filter) {
    }

    $onInit() {
        this.prepareUnit();
    }

    prepareUnit = () => {
        if(this.units && this.units.find((unit) => unit === this.defaultUnit)) {
            this.carouselIndex = this.units.findIndex((unit) => unit === this.defaultUnit);
        }
    }

    nextUnit = () => {
        if(this.units && this.carouselIndex < this.units.length-1) {
            this.carouselIndex++;
        } else {
            this.carouselIndex = 0;
        }
    }

    polarToCartesian = (center, radius, angleInDegrees) => {
        const angleInRadians = (angleInDegrees -90) * Math.PI / 180.0;

        return {
            x: center + (radius * Math.cos(angleInRadians)),
            y: center + (radius * Math.sin(angleInRadians)),
        };
    };

    describeArc = (center, radius, startAngle, endAngle, hasBadge) => {
        if (hasBadge) {
            const rest = 328;
            startAngle = startAngle / 360 * rest;
            endAngle = endAngle / 360 * rest;
        }

        endAngle = endAngle <= 0 ? 0 : endAngle;
        endAngle = endAngle >= 359.9 ? 359.9 : endAngle;

        const start = this.polarToCartesian(center, radius, endAngle);
        const end = this.polarToCartesian(center, radius, startAngle);

        const largeArcFlag = endAngle - startAngle <= 180 ? '0' : '1';

        return [
            'M', start.x, start.y,
            'A', radius, radius, 0, largeArcFlag, 0, end.x, end.y,
        ].join(' ');
    };


    resizeProgress = (center: number, radius: number, percentage: number) => {
        const progress = Math.round(percentage * 3.6);

        this.$element.find('path').attr('d', this.describeArc(center, radius, 0, progress, this.badge));
    };

    $onChanges = () => {
        this.center = this.size / 2;
        this.radius = (this.size / 2) - this.stroke;

        let percentage = this.total[this.carouselIndex] ? 100 / this.total[this.carouselIndex] * (this.part || 1) : 0;

        percentage = percentage >= 100 ? 100 : percentage;

        this.resizeProgress(this.center, this.radius, percentage);
    };

    partIsSmallerThanZero = () => {
        return this.part < 0;
    };

    isCircleTrackComplete = () => {
      return !(this.part > 0);
    };

    partContentFiltered = () => {
        return this.filter ? this.$filter(this.filter[0])(this.part, this.filter[1]) : this.part;
    };

    partsContentFiltered = () => {
        return this.filter ? this.$filter(this.filter[0])(this.parts[this.carouselIndex], this.filter[1]) : this.parts[this.carouselIndex];
    };
}

export class CircleComponent implements IComponentOptions {
    readonly bindings: any;
    readonly controller: Injectable<IControllerConstructor>;
    readonly templateUrl: string;

    constructor() {
        this.bindings = {
            badge: '<',
            part: '<',
            parts: '<',
            size: '<',
            total: '<',
            icon: '<',
            unit: '@',
            filter: '<',
            carousel: '<',
            units: '<',
            defaultUnit: '<',
            activityDescriptions: '<',
        };
        this.controller = CircleCtrl;
        this.templateUrl = 'circle/circle.component.html';
    }
}
