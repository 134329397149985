import { IComponentOptions, IController, IOnInit, IOnDestroy } from 'angular';
import { ShipInfoService } from '../../services/ShipInfoService';
import { UserInfoService } from '../../services/UserInfoService';

class HomeController implements IController, IOnInit, IOnDestroy {
    static $inject = ['shipInfoService', 'userInfoService'];
    private shipInfo: any;
    private userInfo: any;
    private shipName: any;

    constructor(readonly shipInfoService: ShipInfoService,
                readonly userInfoService: UserInfoService) {
    }

    $onInit() {
        this.shipInfoService.subscribe(this.getShipName);
        this.shipInfoService.subscribe(this.updateShipInfo);
        this.userInfoService.subscribe(this.updateUserInfo);
    }

    $onDestroy = () => {
        this.shipInfoService.unsubscribe(this.getShipName);
        this.shipInfoService.unsubscribe(this.updateShipInfo);
        this.userInfoService.unsubscribe(this.updateUserInfo);
    };

    private updateShipInfo = (shipInfo) => {
        this.shipInfo = shipInfo;
    };

    private updateUserInfo = (userInfo) => {
        this.userInfo = userInfo;
    };

    private getShipName = (): any => {
        this.shipInfoService.getShip().then((ship) => {
            this.shipName = ship.name;
        }).catch((err) => {
            console.log(`Failed to get ship name ${JSON.stringify(err)}`);
        });
    };
}

export class HomeComponent implements IComponentOptions {
    readonly controller = HomeController;
    readonly templateUrl = 'home/home.component.html';
}
