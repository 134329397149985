// Import styles
import '../styles/main.scss';

import angular from 'angular';
import 'angular-ui-router';
import 'angular-material';

import './templates';

import { fetchConfig } from './config';
import { routes } from './app.routes';

import { config } from '../config';
import { name, version } from '../../package.json';

import Rollbar from 'rollbar';

// framework imports
import { AccordionComponent } from './components/accordion/accordion.component';
import { ShowMoreButtonComponent } from './components/show-more-button/show-more-button.component';
import { ToggleCollapseButtonComponent } from './components/toggle-collapse-button/toggle-collapse-button.component';
import { ActivityRibbonComponent } from './components/activity-ribbon/activity-ribbon.component';
import { CircleComponent } from './components/circle/circle.component';

import { xDirective } from './directives/x';
import { yDirective } from './directives/y';
import { rDirective } from './directives/r';
import { widthDirective } from './directives/width';
import { heightDirective } from './directives/height';

import { formatDuration } from './filters/format-duration.filter';
import { formatDateTimeZone } from './filters/format-date-timezone.filter';
// end framework imports

import { AuthService } from './services/AuthService';
import { ActivityService } from './services/ActivityService';
import { ActivityLogService } from './services/ActivityLogService';
import { LayoutService } from './services/LayoutService';
import { ScheduleService } from './services/ScheduleService';
import { ShipInfoService } from './services/ShipInfoService';
import { UserInfoService } from './services/UserInfoService';
import { ToastService } from './services/ToastService';

import { loginInterceptorProvider } from './interceptors/login-interceptor';
import { responseNotificationInterceptorProvider } from './interceptors/response-notification-interceptor';
import { updateInterceptor } from './interceptors/update-interceptor';

import { JobWithActivitiesComponent } from './components/job-with-activities/job-with-activities.component';
import { ActivityComponent } from './components/activity/activity.component';
import { CurrentActivityComponent } from './components/current-activity/current-activity.component';
import { HomeComponent } from './components/home/home.component';
import { LoginComponent } from './components/login/login.component';
import { NavigationComponent } from './components/navigation/navigation.component';
import { PastActivityComponent } from './components/past-activity/past-activity.component';
import { ScheduleComponent } from './components/schedule/schedule.component';
import { ScheduledJobComponent } from './components/scheduled-job/scheduled-job.component';
import { ToolbarComponent } from './components/toolbar/toolbar.component';
import { EditJobComponent } from './components/edit-job/edit-job.component';
import { GraphQLClient } from './graphql/GraphQLClient';
import { ApolloClient, InMemoryCache } from '@apollo/client/core';


const app = angular.module('aboard', [
    'ui.router',
    'ngMaterial',
    'templates',
]);

const rollbarConfig: Rollbar.Configuration = {
    accessToken: 'b4702c7b59e3420bb874c3e8db7033b2',
    captureUncaught: true,
    captureUnhandledRejections: true,
    environment: config.env,
    payload: {
        client: {
            javascript: {
                code_version: version,
            },
        },
    },
  };

const rollbar = new Rollbar(rollbarConfig);
app.factory('rollbar', function() {
    return rollbar;
});

app.factory('apolloClient', function () {
    const cache = new InMemoryCache();
      return new ApolloClient({
        cache: cache,
        uri: '/v1/graphql/',
        name: name,
        version: version,
        defaultOptions: {
            query: {
                fetchPolicy: 'no-cache',
            },
        },
    });
});

// framework components
app.component('onbAccordion', new AccordionComponent());
app.component('onbActivityRibbon', new ActivityRibbonComponent());
app.component('onbShowMoreButton', new ShowMoreButtonComponent());
app.component('onbToggleCollapseButton', new ToggleCollapseButtonComponent());
app.component('onbCircle', new CircleComponent());

app.directive('onbX', xDirective);
app.directive('onbY', yDirective);
app.directive('onbR', rDirective);
app.directive('onbWidth', widthDirective);
app.directive('onbHeight', heightDirective);

app.decorator('$exceptionHandler', ['$delegate', '$injector', '$window', 'rollbar', function($delegate, $injector, $window, rollbar) {
    return function(exception, cause) {
        rollbar.error(exception, {cause: cause}, function(err, data) {
            const $rootScope = $injector.get('$rootScope');
            $rootScope.$emit('rollbar:exception', {
                exception: exception,
                err: err,
                data: data ? data.result : null,
              });
        });
        $delegate(exception, cause);
    };
}]);

app.filter('formatDuration', formatDuration);
app.filter('formatDateTimeZone', formatDateTimeZone);
// end framework components

app.config(['$urlRouterProvider', '$stateProvider', routes]);
app.config(['$httpProvider', loginInterceptorProvider]);
app.config(['$httpProvider', responseNotificationInterceptorProvider]);
app.config(['$httpProvider', updateInterceptor]);

app.service('authService', AuthService);
app.service('activityLogService', ActivityLogService);
app.service('activityService', ActivityService);
app.service('graphQLClient', GraphQLClient);
app.service('layoutService', LayoutService);
app.service('scheduleService', ScheduleService);
app.service('shipInfoService', ShipInfoService);
app.service('userInfoService', UserInfoService);
app.service('toastService', ToastService);

app.component('onbActivity', new ActivityComponent());
app.component('onbCurrentActivity', new CurrentActivityComponent());
app.component('onbHome', new HomeComponent());
app.component('onbJobWithActivities', new JobWithActivitiesComponent());
app.component('onbLogin', new LoginComponent());
app.component('onbNavigation', new NavigationComponent());
app.component('onbPastActivity', new PastActivityComponent());
app.component('onbSchedule', new ScheduleComponent());
app.component('onbScheduledJob', new ScheduledJobComponent());
app.component('onbToolbar', new ToolbarComponent());
app.component('onbEditJob', new EditJobComponent());

app.controller('AppController', ['$rootScope', 'toastService', function ($rootScope, toastService) {
    $rootScope.$on('app:notify', (event: string, { message }: any) => toastService.notify(message));
    $rootScope.$on('app:update', (event: string, { version }: any) => toastService.showUpdate(`New version ${version} available`));
}]);

// Production enhancements
// https://docs.angularjs.org/guide/production
app.config(['$compileProvider', function ($compileProvider) {
    $compileProvider.debugInfoEnabled(false);
    $compileProvider.commentDirectivesEnabled(false);
    $compileProvider.cssClassDirectivesEnabled(false);
}]);

(async () => {
    const config = await fetchConfig();

    app.constant('config', config);

    angular.bootstrap(document.body, ['aboard'], {
        strictDi: true,
    });
})();
