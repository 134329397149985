import { IComponentOptions, IController, IOnInit } from 'angular';
import { ScheduleService } from '../../services/ScheduleService';
import { ShipInfoService } from '../../services/ShipInfoService';
import { JobNew } from '../../models/JobNew';

class EditJobController implements IController, IOnInit {
    static $inject = ['$state', 'scheduleService', 'shipInfoService'];

    private job: JobNew;
    private isEdit: boolean;

    private shipCurrentTimeZone: string;

    private addJob: () => void;
    private updateJob: () => void;
    private resetJob: () => void;

    constructor(readonly $state,
                readonly scheduleService: ScheduleService,
                readonly shipInfoService: ShipInfoService) {
    }

    $onInit() {
        this.shipInfoService.getShip().then((ship) =>  {
            this.shipCurrentTimeZone = ship.administrativeTimezone;
        });
    }

    add = () => {
        if(this.isNotValid()){
            return;
        }
        this.addJob();
    };

    update = () => {
        if(this.isNotValid()){
            return;
        }
        this.updateJob();
    };

    cancel = () => {
        this.resetJob();
    };

    isNotValid = (): boolean => {
        return !(typeof this.job === 'object' &&
            this.notEmptyString(this.job.jobName) &&
            this.notEmptyString(this.job.pickUpLocation) &&
            this.notEmptyString(this.job.destinationLocation) &&
            (this.job.scheduledTime as any) instanceof Date
        );
    };

    notEmptyString = (value: string) => {
        return typeof value === 'string' && value.trim().length > 0;
    };
}

export class EditJobComponent implements IComponentOptions {
    readonly controller = EditJobController;
    readonly templateUrl = 'edit-job/edit-job.component.html';
    readonly bindings = {
        job: '=',
        isEdit: '=',
        addJob: '&',
        updateJob: '&',
        resetJob: '&',
    }
}
