import { SubscriptionHandlerService } from './SubscriptionHandlerService';
import { Activity } from '../models/Activity';
import { Shift } from '../models/Shift';
import { Job } from '../models/Job';
import { ACTIVITY_NUMBERS } from '../graphql/query/ActivityNumbers';
import { ActivityNumbersQuery, ActivityNumbersQueryVariables } from '../../types.generated';
import { GraphQLClient } from '../graphql/GraphQLClient';
import moment from 'moment';
import Rollbar from 'rollbar';

export interface EditActivityData {
    activityId: string,
    finished: number,
    started: number
}

export class ActivityLogService {
    static IID = 'activityLogService';

    static $inject = ['$http', '$interval', GraphQLClient.IID, 'rollbar'];
    static serviceRequestInterval = 2000;

    private updateInterval: Promise<any>;
    private activityLogServiceUrl = '/ship/rest/activitylog/';
    private subscriptions: SubscriptionHandlerService<any> = new SubscriptionHandlerService();

    constructor(private $http, private $interval, readonly graphQLClient: GraphQLClient, readonly rollbar: Rollbar) {
        this.subscriptions.onFirst(this.startCycle);
        this.subscriptions.onLast(this.endCycle);
        this.graphQLClient = graphQLClient;
        this.rollbar = rollbar;
    }

    public subscribe = this.subscriptions.add;
    public publish = this.subscriptions.publish;
    public unsubscribe = this.subscriptions.remove;

    // Start a new request after the configured interval has been reached
    private startCycle = () => {
        this.getCurrentActivity();
        this.updateInterval = this.$interval(this.getCurrentActivity, ActivityLogService.serviceRequestInterval);
    };

    // End the request cycle
    private endCycle = () => {
        this.$interval.cancel(this.updateInterval);
    };

    getCurrentActivity = (): angular.IPromise<Activity> => {
        return this.$http.get(this.activityLogServiceUrl)
            .then((response) => {
                if (response.status != 204) {
                    const activity = response.data as Activity;
                    if (this.needsNumbers(activity)) {
                        return this.fetchActivityNumbers(activity, {
                            between: {
                                from: moment(activity.started).format(),
                            },
                        })
                            .then((currentActivity: Activity) => {
                                this.subscriptions.publish(currentActivity);
                                return currentActivity;
                            });
                    } else {
                        this.subscriptions.publish(activity);
                        return activity;
                    }
                }
                return null;
            }).catch((err) => {
                // This function is called every 2 seconds.
                // Don't spam rollbar when one fails please.
                console.log(`getCurrentActivity ${JSON.stringify(err)}`);
                return null;
            });
    };

    private async fetchActivityNumbers(activity: Activity, variables: ActivityNumbersQueryVariables): Promise<Activity> {
        return this.graphQLClient.query({
            query: ACTIVITY_NUMBERS,
            variables: variables,
        }).then((
            { data:
                {
                    measurements: {
                        duration: duration,
                        fuelEconomy: fuelEconomy,
                        speedOverGround: speedOverGround,
                        speedThroughWater: speedThroughWater,
                    },
                },
            }: { data: ActivityNumbersQuery
            }) => {
                if (activity.fuelEfficiency === null || activity.fuelEfficiency === undefined) {
                    activity.fuelEfficiency = {
                        absoluteFuelConsumption: 0,
                        distanceThroughWater: 0,
                        durationSeconds: 0,
                        fuelConsumptionHour: 0,
                        fuelConsumptionNm: 0,
                        fuelEfficiencyHour: 0,
                        fuelEfficiencyNm: 0,
                    };
                }
                if (duration) {
                    activity.fuelEfficiency.durationSeconds = duration.durationSeconds;
                }

                if (fuelEconomy) {
                    if (fuelEconomy.perHour) {
                        activity.fuelEfficiency.absoluteFuelConsumption = fuelEconomy.perHour.liter;
                        activity.fuelEfficiency.fuelConsumptionHour = fuelEconomy.perHour.avgLiterPerHour;
                        activity.fuelEfficiency.fuelEfficiencyHour = fuelEconomy.perHour.efficiency;
                    }
                    if (fuelEconomy.overGround) {
                        activity.fuelEfficiency.fuelConsumptionNm = fuelEconomy.overGround.literPerNauticalMile;
                        activity.fuelEfficiency.fuelEfficiencyNm = fuelEconomy.overGround.efficiency;
                    }
                    if (fuelEconomy.throughWater) {
                        activity.fuelEfficiency.fuelConsumptionNm = fuelEconomy.throughWater.literPerNauticalMile;
                        activity.fuelEfficiency.fuelEfficiencyNm = fuelEconomy.throughWater.efficiency;
                    }
                }
                if (speedOverGround) {
                    activity.fuelEfficiency.distanceThroughWater = speedOverGround.nauticalMile;
                }
                if (speedThroughWater) {
                    activity.fuelEfficiency.distanceThroughWater = speedThroughWater.nauticalMile;
                }
                return activity;
            });
    }

    private needsNumbers(activity: Activity): boolean {
        if (activity) {
            return isNullOrUndefined(activity.fuelEfficiency)
            || isNullOrUndefined(activity.fuelEfficiency.absoluteFuelConsumption)
            || activity.fuelEfficiency.absoluteFuelConsumption <= 0;
        }
        return false;
    }

    getActivity = (id: string): angular.IPromise<Activity> => {
        return this.$http.get(this.activityLogServiceUrl + id);
    };

    editActivity = (id: string, data: EditActivityData): angular.IPromise<any> => {
        return this.$http.put(this.activityLogServiceUrl + id, data)
            .then(
                (response) => {
                    return response;
                },
                (error) => {
                    console.warn(`ActivityLogService.editActivity returned a ${error.status}`);
                    return error;
                });
    };

    getActivityHistory = (): angular.IPromise<Activity[]> => {
        return this.$http.get(this.activityLogServiceUrl + 'history')
            .then((response) => {
                const activityHistory = response.data as Activity[];
                return Promise.all(activityHistory.map((activity) => {
                    if (this.needsNumbers(activity)) {
                        return this.fetchActivityNumbers(activity, {
                            between: {
                                from: moment(activity.started).format(),
                                until: moment(activity.finished).format(),
                            },
                            targetLiterPerHour: activity.fuelProfile ? activity.fuelProfile.targetPerHour : null,
                            targetLiterPerNauticalMile: activity.fuelProfile ? activity.fuelProfile.targetPerNM : null,
                        });
                    }
                    return activity;
                }));
            });
    };

    jobsForCurrentShift = (): angular.IPromise<Job[]> => {
        return this.$http.get('/ship/rest/shift/jobs')
            .then((response) => {
                return response.data;
            });
    };

    currentShift = (): angular.IPromise<Shift> => {
        return this.$http.get('/ship/rest/shift')
            .then((response) => {
                if (204 === response.status) {
                    return null;
                }
                return response.data;
            });
    };

    startOrContinueShift = (): angular.IPromise<any> => {
        return this.$http.put('/ship/rest/shift');
    };

    stopShift = (): angular.IPromise<any> => {
        return this.$http.post('/ship/rest/shift')
            .then((response) => {
                return response.data;
            });
    }
}
function isNullOrUndefined<T> (value: T | null | undefined): boolean {
    return value === null || value === undefined;
}

