import * as angular from 'angular';

export class LayoutService {
    static NIGHTMODE_CLASS = 'nightmode';

    nightmode = false;

    toggleNightmode(): boolean {
        this.nightmode = !this.nightmode;

        angular.element(document.body)[this.nightmode ? 'addClass' : 'removeClass'](LayoutService.NIGHTMODE_CLASS);

        return this.nightmode;
    }
}
