import { IComponentOptions, IController } from 'angular';
import { GraphQLClient } from '../../graphql/GraphQLClient';
import { ActivityLogService } from '../../services/ActivityLogService';
import { AuthService } from '../../services/AuthService';

class OnbToolbarComponentController implements IController {
    static $inject = ['$q', '$state', ActivityLogService.IID, AuthService.IID, GraphQLClient.IID];
    private shipInfo: any;
    private userInfo: any;
    private shipName: any;

    constructor(
        private $q,
        private $state,
        readonly activityLogService: ActivityLogService,
        readonly authService: AuthService,
        readonly graphQLClient: GraphQLClient,
    ) { }

    stopShift() {
        return this.$q.all([
            this.activityLogService.stopShift(),
            this.graphQLClient.logout(),
            this.authService.logout(),
        ]).then(() => {
            // logged out, cookie invalidated
            this.$state.go('login');
        });
    }
}

export class ToolbarComponent implements IComponentOptions {
    readonly controller = OnbToolbarComponentController;
    readonly templateUrl = 'toolbar/toolbar.component.html';
    readonly bindings = {
        shipName: '<',
        shipInfo: '<',
        userInfo: '<',
    };
}
