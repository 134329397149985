import type { IRootScopeService } from 'angular';
import type { Config } from '../config';

const HEADER_APP_VERSION = 'X-App-Version';

export function createUpdateCheck(current: string) {
    if (!current) {
        throw new RangeError(`Current version "${current}" is not valid`);
    }

    return (version: string): boolean => version && current !== version;
}

export function updateInterceptor($httpProvider): void {
    $httpProvider.interceptors.push(['$rootScope', 'config', ($rootScope: IRootScopeService, config: Config) => {

        let isUpdating = false;
        const hasUpdate = createUpdateCheck(config.VERSION);

        return {
            response(response) {
                if (isUpdating) {
                    return response;
                }

                const headers = response.headers();
                const version = headers[HEADER_APP_VERSION] || headers[HEADER_APP_VERSION.toLowerCase()];

                if (hasUpdate(version)) {
                    isUpdating = true;

                    $rootScope.$broadcast('app:update', { version });
                }

                return response;
            },
        };
    }]);
}
