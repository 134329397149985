import { Injectable, IDirectiveFactory, IDirective } from 'angular';

const factory: IDirectiveFactory = (): IDirective => {
    return {
        link: (scope, element, attrs) => {
            return scope.$watch(attrs.onbX, (value: number) => {
                element[0].tagName === 'circle' ? element.attr('cx', value) : element.attr('x', value);
            });
        },
    };
};

export const xDirective: Injectable<IDirectiveFactory> = [factory];
