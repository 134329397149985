import { ApolloClient, NormalizedCacheObject, QueryOptions } from '@apollo/client/core';
import moment from 'moment';
import type { CreateTokenMutation, CreateTokenMutationVariables, RefreshTokenMutationVariables, RefreshTokenMutation } from '../../types.generated';
import { CREATE_TOKEN, REFRESH_TOKEN } from './mutation/AccessToken';
import Rollbar from 'rollbar';

export class GraphQLClient {
    static IID = 'graphQLClient';
    static $inject = ['apolloClient', 'rollbar', '$state']

    private _refreshToken: string;
    private _accessToken: Promise<AccessToken> | null;

    constructor(readonly apolloClient: ApolloClient<NormalizedCacheObject>, readonly rollbar: Rollbar, readonly $state) {
        if (window.localStorage.refreshToken) {
            this._refreshToken = window.localStorage.refreshToken;
        }
    }

    async getAccessToken(): Promise<string> {
        if (await this.isAccessTokenExpired()) {
            this._accessToken = this.refreshTokens();
        }
        return (await this._accessToken).accessToken;
    }

    private async refreshTokens(): Promise<AccessToken> {
        const variables: RefreshTokenMutationVariables = {
            refreshToken: this.refreshToken,
        };
        return this.apolloClient.mutate({
            mutation: REFRESH_TOKEN,
            variables,
            errorPolicy: 'none',
        }).then(({ data: { refreshToken } }: { data: RefreshTokenMutation }) => {
            const {accessToken: accessToken, refreshToken: refreshedToken, expiresIn: expiresIn} = refreshToken;
            this.refreshToken = refreshedToken;
            return new AccessToken(
                accessToken,
                expiresIn,
            );
        }).catch((err) => {
            this.rollbar.error(`Failed to refresh tokens; ${err} \nVariables: ${JSON.stringify(variables)}`);
            this.$state.go('login');
            throw err;
        });
    }

    private async isAccessTokenExpired(): Promise<boolean> {
        if (this._accessToken) {
            return (await this._accessToken).isExpired();
        }
        return true;
    }

    get refreshToken(): string {
        return this._refreshToken;
    }

    set refreshToken(refreshToken: string) {
        this._refreshToken = refreshToken;
        window.localStorage.refreshToken = refreshToken;
    }

    async logout(): Promise<void> {
        return Promise.resolve(window.localStorage.removeItem('refreshToken'));
    }

    private async doLogin(username: string, password: string): Promise<AccessToken> {
        const variables: CreateTokenMutationVariables = {
            username,
            password,
        };
        return this.apolloClient.mutate({
            mutation: CREATE_TOKEN,
            variables,
            errorPolicy: 'none',
        }).then(({ data: { createToken } }: { data: CreateTokenMutation }) => {
            const {accessToken, refreshToken, expiresIn} = createToken;
            this.refreshToken = refreshToken;
            return new AccessToken(
                accessToken,
                expiresIn,
            );
        });
    }

    async login(username: string, password: string): Promise<AccessToken> {
        this._accessToken = this.doLogin(username, password);
        return await this._accessToken;
    }

    async query(options: QueryOptions): Promise<any> {
        return this.getAccessToken().then((accessToken) => {
            return this.doQuery({ options, accessToken });
        });
    }

    private async doQuery({ options: { query, variables }, accessToken }: { options: QueryOptions; accessToken: string; }): Promise<any> {
        return this.apolloClient.query({
            query: query,
            variables: variables,
            context: {
                headers: {
                    authorization: `Bearer ${accessToken}`,
                },
            },
        })
        .catch((err) => {
            const {graphQLErrors, clientErrors, networkError} = err;
            if (Array.isArray(graphQLErrors) && graphQLErrors.length) {
                this.rollbar.error('GraphQL errors', graphQLErrors);
            }
            if (Array.isArray(clientErrors) && clientErrors.length) {
                this.rollbar.error('GraphQL client errors', clientErrors);
            }
            if (networkError) {
                this.rollbar.error('GraphQL network error', networkError.statusCode);
            }
            throw err;
        });
    }
}


class AccessToken {
    readonly accessToken: string;
    readonly accessTokenExpiration: moment.Moment;

    constructor(accessToken: string, expiresIn: number) {
        this.accessToken = accessToken;
        this.accessTokenExpiration = moment().add(expiresIn, 'seconds').subtract(2, 'minutes');
    }

    isExpired(): boolean {
        if (this.accessTokenExpiration) {
            return !moment().isBefore(this.accessTokenExpiration);
        }
        return true;
    }
}
