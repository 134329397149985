import { IComponentOptions, IController, IOnInit, IOnDestroy } from 'angular';
import { ShipInfoService } from '../../services/ShipInfoService';
import { Activity } from '../../models/Activity';
import { ActivityService } from '../../services/ActivityService';
import { ActivityLogService } from '../../services/ActivityLogService';

class CurrentActivityController implements IController, IOnInit, IOnDestroy {
    static $inject = ['$timeout', 'activityLogService', 'activityService', 'shipInfoService'];

    private onToggleDetails: (boolean) => void;
    private shipCurrentTimeZone: string;

    private showDetails = false;
    private equipment: any[];
    private remarkChanged = false;
    private equipmentChanged = false;
    private remarkUpdated = false;
    private currentActivity: Activity;

    private equipmentUpdated = false;
    private refreshRateInMs = 1000;
    private activityDurationTillNow = 0;
    private durationTimer: Promise<any>;

    constructor(
        readonly $timeout,
        readonly activityLogService: ActivityLogService,
        readonly activityService: ActivityService,
        readonly shipInfoService: ShipInfoService,
    ) {
        this.activityLogService.subscribe(this.checkIfUpdatesAreNeeded);
    }

    $onInit() {
        this.getEquipment();
        this.getCurrentActivity();
        this.scheduleDurationUpdate();
    }

    $onDestroy = () => {
        this.$timeout.cancel(this.durationTimer);
        this.activityLogService.unsubscribe(this.checkIfUpdatesAreNeeded);
    };

    updateRemark = () => {
        if (this.remarkChanged == true) {
            this.activityService.updateRemark(this.currentActivity.id, this.currentActivity.remark)
                .then((response) => {
                    if (response.status == 204) {
                        this.remarkChanged = false;
                        this.remarkUpdated = true;
                    } else {
                        console.log('In updateRemark(), something went wrong.');
                        console.log(response);
                    }
                });
        }
    };

    scheduleDurationUpdate = () => {
        this.durationTimer = this.$timeout(() => {
            this.updateDuration();
            this.scheduleDurationUpdate();
        }, this.refreshRateInMs);
    };

    updateDuration = () => {
        if (this.currentActivity) {
            const nowInMs = new Date().getTime();
            const startDateInMs = new Date(this.currentActivity.started).getTime();

            this.activityDurationTillNow = nowInMs - startDateInMs;
        }
    };

    updateEquipment = () => {
        if (this.equipmentChanged == true) {
            this.activityService.updateEquipment(this.currentActivity.id, this.currentActivity.equipment)
                .then((response) => {
                    if (response.status == 204) {
                        this.equipmentChanged = false;
                        this.equipmentUpdated = true;
                    } else {
                        console.log('In updateEquipment(), something went wrong.');
                        console.log(response);
                    }
                });
        }
    };

    setRemarkChanged = () => {
        this.remarkChanged = true;
    };

    setEquipmentChanged = () => {
        this.equipmentChanged = true;
    };

    showSelectActivityMessage = (): boolean => {
        return this.currentActivity === undefined || this.currentActivity === null;
    };

    toggleDetails = () => {
        this.showDetails = !this.showDetails;
        if (this.onToggleDetails) {
            this.onToggleDetails(this.showDetails);
        }
    };

    private getEquipment = (): void => {
        this.shipInfoService.getShip().then((shipInfo) => {
            this.equipment = shipInfo.equipment;
        })
        .catch((err) => {
            console.log(`Failed to get equipment ${JSON.stringify(err)}`);
        });
    };

    private getCurrentActivity = () => {
        this.activityLogService.getCurrentActivity().then((current) => {
            this.currentActivity = current;
            this.remarkChanged = false;
            this.equipmentChanged = false;
        });
    };

    private checkIfUpdatesAreNeeded = (currentActivityFromServer: Activity | null) => {
        if (!currentActivityFromServer) {
            return;
        }

        const activityIsChanged = () => {
            if (this.currentActivity && this.currentActivity.id) {
                return this.currentActivity.id !== currentActivityFromServer.id;
            }

            return !this.currentActivity && currentActivityFromServer;
        };

        if (activityIsChanged()) {
            this.updateCurrentActivityAndDetails(currentActivityFromServer);
        } else {
            this.updateCurrentActivity(currentActivityFromServer);
        }
    };

    private updateCurrentActivity = (currentActivityFromServer: Activity) => {
        let remark = '';
        let equipment = {};

        if (this.currentActivity) {
            if (this.currentActivity.remark) {
                remark = this.currentActivity.remark;
            }

            if (this.currentActivity.equipment) {
                equipment = this.currentActivity.equipment;
            }

            this.currentActivity = currentActivityFromServer;
            this.currentActivity.remark = remark;
            this.currentActivity.equipment = equipment;
        }
    };

    private updateCurrentActivityAndDetails = (currentActivityFromServer: Activity) => {
        this.currentActivity = currentActivityFromServer;
    };
}

export class CurrentActivityComponent implements IComponentOptions {
    readonly controller = CurrentActivityController;
    readonly templateUrl = 'current-activity/current-activity.component.html';
    readonly bindings = {
        shipCurrentTimeZone: '<',
        onToggleDetails: '<?',
    };
}
