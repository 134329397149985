/**
 * This HTTP Interceptor will intercept all error responses.
 * It will show `Not found` and `Unauthorized` to the user and log everything else to the console.
 */

// TODO We should integrate Sentry or another service to track all errors that occur
export const responseNotificationInterceptorProvider = ($httpProvider) => {
    $httpProvider.interceptors.push(['$q', '$log', '$rootScope', '$location', ($q, $log, $rootScope, $location) => {
        return {
            responseError: (response) => {
                const { status, statusText } = response;

                switch (status) {
                    case -1:
                    case 404:
                    case 502:
                        $rootScope.$broadcast('app:notify', { message: 'Onboard Server cannot be reached' });
                        break;
                    case 403:
                        if ($location.path() !== '/login') {
                            $rootScope.$broadcast('app:notify', { message: 'Not authorized' });
                        }
                        break;
                    default:
                        $log.error(`Error "${ status }": ${ statusText }`);
                }

                return $q.reject(response);
            },
        };
    }]);
};
