import { SubscriptionHandlerService } from './SubscriptionHandlerService';

export class ShipInfoService {
    private subscriptions: SubscriptionHandlerService<any> = new SubscriptionHandlerService();
    private cycle: number | undefined;

    static $inject = ['$http'];

    constructor(public $http) {
        this.subscriptions.onFirst(this.startCycle);
        this.subscriptions.onLast(this.endCycle);
    }

    public subscribe = this.subscriptions.add;
    public publish = this.subscriptions.publish;
    public unsubscribe = this.subscriptions.remove;

    /**
     * Start a new request after the configured interval has been reached
     */
    private startCycle = () => {
        this.cycle = window.setInterval(this.getShipInfo, 5000);
    };

    /**
     * End the request cycle
     */
    private endCycle = () => {
        window.clearInterval(this.cycle);
    };

    private getShipInfo = (): void => {
        this.$http.get('/ship/rest/ship/shipinfo')
            .then((response) => {
                this.subscriptions.publish(response.data);
            })
            .catch((err) => {
                console.log(`Failed to get ship info ${JSON.stringify(err)}`);
            });
    };

    getShip = (): angular.IPromise<any>  => {
        return this.$http.get('/ship/rest/ship').then((response) => {
          return response.data;
        });
    };
}
