export class AuthService {
    static IID = 'authService';

    static $inject = ['$http'];

    private logoutURL = '/ship/rest/auth/';

    constructor(private $http) {
    }

    logout = (): angular.IPromise<any> => {
        return this.$http.post(this.logoutURL)
            .then(() => {
                console.log('logged out');
            });
    }

}
