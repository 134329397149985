import { SubscriptionHandlerService } from './SubscriptionHandlerService';

export class UserInfoService {
    private subscriptions: SubscriptionHandlerService<any> = new SubscriptionHandlerService();
    static $inject = ['$http'];

    constructor(public $http) {
        this.subscriptions.onFirst(this.getUserInfo);
    }

    public subscribe = this.subscriptions.add;
    public publish = this.subscriptions.publish;
    public unsubscribe = this.subscriptions.remove;

    private getUserInfo = (): void => {
        this.$http.get('/ship/rest/ship/userinfo')
            .then((response) => {
                this.subscriptions.publish(response.data);
            })
            .catch((err) => {
                console.log(`Failed to get user info ${JSON.stringify(err)}`);
            });
    };
}
