export const formatDuration = () => {
    return (durationInMs: number, hoursAndMinutes): string => {
        if (durationInMs === undefined) {
            return '0s';
        }

        let output = '';

        const secondInMs = 1000;
        const minuteInMs = 60 * secondInMs;
        const hourInMs = 60 * minuteInMs;
        const dayInMs = 24 * hourInMs;

        const days = (<any>Math).trunc(durationInMs / dayInMs);
        const remainingHoursInMs = durationInMs % dayInMs;
        const hours = (<any>Math).trunc(remainingHoursInMs / hourInMs);
        const remainingMinutesInMs = remainingHoursInMs % hourInMs;
        const minutes = (<any>Math).trunc(remainingMinutesInMs / minuteInMs) || 0;
        const remainingSeconds = remainingMinutesInMs % minuteInMs;
        const seconds = (<any>Math).trunc(remainingSeconds / secondInMs) || 0;

        const daysFormatting = days > 0 ? `${days}d ` : '';
        const hoursFormatting = hours > 0 ? `${hours}h ` : '';
        const secondsFormatting = seconds < 10 ? `0${seconds}` : `${seconds}`;

        if(hoursAndMinutes) {
            output = `${daysFormatting}${hoursFormatting}${minutes}m`;
        } else {
            output = `${daysFormatting}${hoursFormatting}${minutes}m ${secondsFormatting}s`;
        }
        return output;
    };
};