import { IComponentOptions, IController, IControllerConstructor, Injectable } from 'angular';

class OnbShowMoreButtonController implements IController {
    expanded: boolean;
}

export class ShowMoreButtonComponent implements IComponentOptions {
    readonly bindings: any;
    readonly controller: Injectable<IControllerConstructor>;
    readonly templateUrl: string;

    constructor() {
        this.bindings = {
            expanded: '<',
        };
        this.controller = OnbShowMoreButtonController;
        this.templateUrl = 'show-more-button/show-more-button.component.html';
    }
}
