import { Job } from './Job';

export class JobNew implements Job {
    id: string;
    shipId: string;
    jobName: string;
    pickUpLocation: string;
    destinationLocation: string;
    remark: string;
    scheduledTime: Date;
    active: boolean;
    cancelled: boolean;
    confirmed: boolean;
    finishTime: string;
    startTime: string;
}
